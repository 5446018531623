import styled from 'styled-components'

import { theme } from '@/styles/theme'

import { ExternalLinkIcon } from '@/components/elements/Icon/ExternalLink'
import { RoundedLabel } from '@/components/elements/RoundedLabel'

type Props = {
  imgPath: string
  title?: string
  detail?: string
  isExternalLink?: boolean
}

export const Card = ({ imgPath, title, detail, isExternalLink }: Props) => {
  return (
    <Wrapper>
      <Grid>
        <ImageWrapper>
          <img src={imgPath} width="100%" alt="" />
        </ImageWrapper>
        {title && <Title>{title}</Title>}
      </Grid>
      {detail && (
        <Detail>
          <div>
            <span>{detail}</span>
            {isExternalLink && <StyledExternalLinkIcon size="small" />}
          </div>
        </Detail>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 316px;
  height: 100%;
  background: ${theme.colors.backgroundPaleBlue};
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1047px) {
    width: 100%;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  grid-template-rows: 1fr auto 17px;

  @media screen and (max-width: 1047px) {
    grid-template-rows: 1fr auto 17px;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-area: 1 / 1 / 4 / 4;
  height: 188px;
  transition: opacity 0.25s ease;

  @media screen and (max-width: 1047px) {
    height: 199px;
  }
`

const Title = styled(RoundedLabel)`
  grid-area: 2 / 2 / 3 / 3;
`

const Detail = styled.div`
  background: #fff;
  padding: 18px 26px 22px;
  border-width: 0 4px 4px 4px;
  border-color: ${theme.colors.backgroundPaleBlue};
  border-style: solid;
  border-radius: 0 0 8px 8px;
  line-height: 1.4;
  letter-spacing: 0.08em;
  flex: 1;

  @media screen and (max-width: 1047px) {
    padding: 13px 18px 16px;
    font-size: 14px;
  }
`

const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
  margin-left: 8px;
`
