import type { GetStaticProps } from 'next'

import { NewtClient } from '@/utils/NewtClient'

import { HomePage } from '@/components/pages/Home'

import type { NewsRelease } from '@/types/newsRelease'

type HomeProps = {
  newsRelease: NewsRelease[]
}

const Home = ({ newsRelease }: HomeProps) => {
  return <HomePage newsRelease={newsRelease} />
}

export const getStaticProps: GetStaticProps = async () => {
  const client = new NewtClient()
  const newsRelease = await client.fetchNewsRelease(3)

  return {
    props: {
      newsRelease,
    },
  }
}

export default Home
