import { format } from 'date-fns'

export const formatDate = (date: string, formatType: string): string => {
  try {
    return format(new Date(date), formatType)
  } catch (e) {
    return date
  }
}

export const isEqualYear = (date: string, year: string): boolean => {
  return formatDate(date, 'yyyy') === year
}

export const isEqualMonth = (date: string, month: string): boolean => {
  return formatDate(date, 'M') === month
}
