// 修正案1
import { useEffect, useState } from 'react';

console.log('4')

export const useMpocketScript = (src: string) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null); // エラー状態をstringまたはnullで初期化

  useEffect(() => {
    if (typeof window === 'undefined') return; // SSR対策

    const loadScript = async () => {
      try {
        await new Promise((resolve, reject) => {

          const scriptElement = document.createElement('script');

          let srcWithTime = src;
          const time = new Date().getTime();
          if(srcWithTime.includes('?')){
            srcWithTime += `&_t=${time}`;
          }else{
            srcWithTime += `?_t=${time}`;
          }

          scriptElement.src = srcWithTime;
          scriptElement.async = true;
          console.log('scriptElement.src:', scriptElement.src);
          document.body.appendChild(scriptElement);
          // console.log(`新しいスクリプトを作成しています: ${src}, 非同期設定: ${scriptElement.async}`);

          const existingScript = document.querySelector(`script[src*="${src}"]`);
          if (existingScript) {
            console.log(`スクリプトが既にページに存在します: ${src}`);
            resolve(existingScript);
            return;
          } else {
            console.log(`スクリプトがページに存在しません、追加します: ${src}`);
          }

          // const scriptElement = document.createElement('script');
          // scriptElement.src = src;
          // scriptElement.async = true;

          scriptElement.onload = () => {
            console.log('マジカルポケットの外部スクリプトが読み込まれました。');
            console.log('scriptElement.src:', scriptElement.src);
            setIsLoaded(true);
            resolve(scriptElement);
          };

          scriptElement.onerror = () => {
            const errorMessage = 'マジカルポケットの外部スクリプトの読み込みに失敗しました。';
            console.error(errorMessage);
            setError(errorMessage); // カスタムエラーメッセージをセット
            reject(new Error(errorMessage));
          };

          // document.body.appendChild(scriptElement);
          console.log(`スクリプトがDOMに追加されました: ${src}`);

        });
      } catch (error) {
        setError(error instanceof Error ? error.message : String(error));
      }
    };

    loadScript();
  }, [src]);

  return { isLoaded, error };
};



/*
import { useEffect, useState } from 'react';

export const useMpocketScript = (src: string) => {
  const [isLoaded, setIsLoaded] = useState(false);  // スクリプト(js)読込状況の確認用
  const [error, setError] = useState<string | null>(null);  // エラーメッセージ格納用

  useEffect(() => {
    if (typeof window === 'undefined') return; // SSR対策とスクリプト読み込みフラグ確認

    const loadScript = () => {
      //Promiseを利用した非同期処理
      //各種読み込み状態・処理状態に応じた処理を行うため、Promiseを使用
      return new Promise((resolve, reject) => {
        // 既に同じsrcのスクリプトが存在するか確認
        const existingScript = document.querySelector(`script[src="${src}"]`);  

        if (existingScript) { // 存在している場合
          console.log(`スクリプトが既にページに存在します: ${src}`);
          setIsLoaded(true);
          resolve(existingScript);
          return;
        }
        
        // 新しいスクリプト要素の作成
        const scriptElement = document.createElement('script');
        scriptElement.src = src;
        scriptElement.async = true; // 非同期での読込。可読性重視のため、asyncを採用

        // スクリプトの読込成功時
        scriptElement.onload = () => {
          console.log('外部スクリプトが読み込まれました。');
          setIsLoaded(true);
          resolve(scriptElement);
        };

        // スクリプトの読込失敗時
        scriptElement.onerror = () => {
          const errorMessage = '外部スクリプトの読み込みに失敗しました。';
          console.error(errorMessage);
          setError(errorMessage);
          reject(new Error(errorMessage));  //エラーで拒否
        };

        // スクリプトをDOMに追加
        const appendScript = () => {
          if (document.body) {
            document.body.appendChild(scriptElement);
            console.log(`スクリプトがDOMに追加されました: ${src}`);
          } else {
            console.error('document.bodyが存在しません。スクリプトの追加に失敗しました。');
            reject(new Error('document.bodyが存在しません。スクリプトの追加に失敗しました。')); //エラーで拒否
          }
        };

        // ドキュメントの準備状態に応じて作業を変更
        if (document.readyState === 'loading') {   // ドキュメントが読み込み中の場合
          document.addEventListener('DOMContentLoaded', appendScript);
        } else {  // ドキュメントが読み込み済みの場合
          appendScript();
        }
      });
    };

    // スクリプトを同期的に読込
    //　可読性重視、ハンドリングの容易さを重視しての、async選択
    const syncLoadScript = async () => {
      try {
        await loadScript(); // スクリプトの読み込み待ち
      } catch (error) { // エラーが発生時
        setError(error instanceof Error ? error.message : String(error));
      }
    };

    syncLoadScript(); // スクリプトの同期読込
  }, [src]);

  return { isLoaded, error };
};

*/