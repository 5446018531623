import Link from 'next/link'
import styled, { CSSObject, CSSProp } from 'styled-components'

import { theme } from '@/styles/theme'

import { Line } from '@/components/elements/Line'

export type PickUpItemProps = {
  link: string
  imgPath: string
  imgWidth: number
  imgHeight: number
  title: string
  alignItems: CSSObject['alignItems']
  cssProp: CSSProp
}

export const PickUpItem = ({
  link,
  imgPath,
  imgWidth,
  imgHeight,
  title,
  alignItems,
  cssProp,
}: PickUpItemProps) => {
  return (
    <PickUpItemStyled>
      <Link href={link} passHref>
        <a>
          <PickUpItemImage alignItems={alignItems} cssProp={cssProp}>
            <img
              src={imgPath}
              alt={title}
              width={imgWidth}
              height={imgHeight}
            />
          </PickUpItemImage>
          <PickUpItemTitle>
            <div>
              <h3>{title}</h3>
              <PickUpLine color="trialBlue" direction="horizon" weight={4} />
            </div>
          </PickUpItemTitle>
        </a>
      </Link>
    </PickUpItemStyled>
  )
}

const PickUpItemStyled = styled.li`
  background-color: white;
  border-radius: 8px;
  width: 311px;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 0;

    img {
      transition: opacity 0.25s ease;
    }

    &:hover {
      h3 {
        color: ${theme.colors.hoverLightBlue};
      }

      img {
        opacity: 0.6;
      }
    }
  }

  @media screen and (max-width: 1047px) {
    height: 164px;
    width: 100%;
    max-width: 350px;

    a {
      padding: 20px 10% 24px;
      flex-direction: row;
      height: 100%;
    }
  }
`

const PickUpLine = styled(Line)`
  transition: background-color 0.25s ease;

  ${PickUpItemStyled}:hover & {
    background-color: ${theme.colors.hoverLightBlue};
  }
`

const PickUpItemImage = styled.div<
  Pick<PickUpItemProps, 'alignItems' | 'cssProp'>
>`
  width: 185px;
  height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  width: 185px;
  height: 185px;
  border-radius: 50%;
  background-color: ${theme.colors.backgroundPaleBlue};
  align-items: ${({ alignItems }) => alignItems};

  @media screen and (max-width: 1047px) {
    width: 90px;
    height: 90px;
    justify-content: center;

    ${({ cssProp }) => cssProp};
  }
`

const PickUpItemTitle = styled.div`
  margin-top: 22px;

  h3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 29px;
    margin-bottom: 8px;
    transition: color 0.25s ease;
  }

  @media screen and (max-width: 1047px) {
    width: 50%;
    margin-top: 0;
    margin-left: 13px;
    flex: 1;
    text-align: center;

    > div {
      display: inline-block;
    }

    h3 {
      text-align: center;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 360px) {
    h3 {
      font-size: 16px;
    }
  }
`
