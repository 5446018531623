import type { ComponentPropsWithoutRef } from 'react'
import styled from 'styled-components'

import { theme } from '@/styles/theme'

import Icon from './external-link.svg'

type Props = {
  size?: 'regular' | 'small'
} & ComponentPropsWithoutRef<'svg'>

export const ExternalLinkIcon = ({ size, ...props }: Props) => {
  return <StyledIcon aria-label="新規タブで開く" size={size} {...props} />
}

const StyledIcon = styled(Icon)<Pick<Props, 'size'>>`
  transition: fill 0.3s ease-out;
  fill: ${theme.colors.trialBlue};
  width: ${({ size }) => (size === 'small' ? '12px' : '16px')};
  height: ${({ size }) => (size === 'small' ? '12px' : '16px')};

  @media screen and (max-width: 1047px) {
    width: 12px;
    height: 12px;
  }
`
