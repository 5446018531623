import styled from 'styled-components'

import { theme } from '@/styles/theme'

export const RoundedLabel = styled.div`
  color: #fff;
  background: ${theme.colors.trialBlue};
  border-radius: 23px;
  border: 2px solid #fff;
  padding: 12px 20px;
  font-size: 17px;
  line-height: 1.1;
  letter-spacing: 0.04em;
  font-weight: 700;
  word-break: break-all;
  text-align: center;
  position: relative;
  transition: background 0.25s ease;

  @media screen and (max-width: 1047px) {
    border-radius: 25px;
    font-size: 15px;
    padding: 12px 50px;
  }
`
