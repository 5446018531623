import styled from 'styled-components'

import { theme } from '@/styles/theme'

export const AHeading = styled.h2`
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  color: ${theme.colors.textTitle};
  border-bottom: 1px solid ${theme.colors.grey};
  padding-bottom: 22px;
  margin-bottom: 50px;

  @media screen and (max-width: 1047px) {
    font-size: 19px;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
`

export const BHeading = styled.h3`
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  color: ${theme.colors.trialBlue};
  margin-bottom: 36px;

  @media screen and (max-width: 1047px) {
    font-size: 20px;
    line-height: 130%;
    margin-bottom: 26px;
  }
`

export const CHeading = styled.h4`
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 20px;

  @media screen and (max-width: 1047px) {
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 18px;
  }
`

export const DHeading = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 100%;
  color: ${theme.colors.trialBlue};

  @media screen and (max-width: 1047px) {
    font-size: 24px;
    line-height: 130%;
  }
`
