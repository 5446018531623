import styled from 'styled-components'

export const LinkCardList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(316px, max-content));
  justify-content: center;
  gap: 46px;

  @media screen and (max-width: 1047px) {
    gap: 22px;
  }
`
