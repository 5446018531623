import styled from 'styled-components'

import { IR_PICKUP_ROUTE } from '@/utils/routes'

import { LinkCard } from '@/components/elements/LinkCard'
import { LinkCardList } from '@/components/elements/LinkCardList'
import { SectionTitle } from '@/components/elements/SectionTitle'
import { Spacer } from '@/components/elements/Spacer'

export const IrPickUp = () => {
  return (
    <Container>
      <SectionTitle
        color="normal"
        titleText="Investor Relations"
        subTitleText="株主・投資家向け情報"
      />
      <Spacer h={{ sp: 34, pc: 70 }} />
      <LinkCardList>
        {IR_PICKUP_ROUTE.map(({ path, name, imagePath }) => (
          <LinkCard link={path} title={name} imgPath={imagePath} key={path} />
        ))}
      </LinkCardList>
    </Container>
  )
}

const Container = styled.div`
  width: 1040px;
  margin: 100px auto 130px;

  @media screen and (max-width: 1047px) {
    margin: 60px auto 60px;
    width: 100%;
    padding: 0 20px;
  }
`
