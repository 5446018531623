import styled from 'styled-components'

import { theme } from '@/styles/theme'

import { useMpocketScript } from '@/hooks/useMpocketScript'

export const RecentNewsList = () => {
  useMpocketScript('//irpocket.com/141A/irpocket/config-utils-main.js')
  // useMpocketScript('//irpocket.com/5882/irpocket/config-utils-main.js')

  return <NewsList id="irp-top-list" />
}

const NewsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 1047px) {
    gap: 24px;
  }

  > article {
    padding-bottom: 30px;
    :not(:last-child) {
      border-bottom: 1px solid ${theme.colors.backgroundBlue};
    }

    @media screen and (max-width: 1047px) {
      padding-bottom: 24px;
    }

    > a {
      display: flex;
      cursor: pointer;
      gap: 50px;

      &:hover > div > h3 {
        > svg {
          fill: ${theme.colors.hoverLightBlue};
        }
        > p {
          text-decoration: underline;
          color: ${theme.colors.hoverLightBlue};
          > span {
            color: ${theme.colors.hoverLightBlue};
            display: inline-block;
          }
        }
      }

      @media screen and (max-width: 1047px) {
        flex-direction: column;
        row-gap: 14px;
      }

      > time {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.6;
        letter-spacing: 0;
        color: ${theme.colors.textTitle};
      }

      > div {
        display: flex;
        flex-direction: column;
        row-gap: 14px;

        > ul {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;

          > li {
            font-size: 13px;
            text-align: center;
            line-height: 1;
            color: ${theme.colors.trialBlue};
            list-style: none;
            min-width: 82px;
            padding: 6px 13px;
            border: 1pt solid;
            border-radius: 2pt;
            border-color: ${theme.colors.trialBlue};
          }
        }

        > h3 {
          display: flex;
          gap: 10px;
          transition: fill 0.3s ease;
          @media screen and (max-width: 1047px) {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0;
          }

          > svg {
            fill: ${theme.colors.trialBlue};
            width: 12px;
            height: 28px;
            flex-shrink: 0;
          }

          > p {
            font-size: 16px;
            letter-spacing: 0.64px;
            line-height: 26px;
            color: ${theme.colors.textTitle};
            @media screen and (max-width: 1047px) {
              font-size: 15px;
            }

            > span {
              font-weight: 500;
              font-size: 13px;
              line-height: 1.6;
              color: ${theme.colors.textSecond};
              margin-left: 10px;
              @media screen and (max-width: 1047px) {
                font-size: 11px;
              }
          }
        }
      }
    }
  }
`
