import Link from 'next/link'
import styled from 'styled-components'

import { Button } from '@/components/elements/Button'
import { NewsItem } from '@/components/elements/News/NewsItem'
import { Spacer } from '@/components/elements/Spacer'
import { DHeading } from '@/components/layouts/Ir/Heading'
import { RecentNewsList } from '@/components/pages/Ir/RecentNewsList'

import type { NewsRelease } from '@/types/newsRelease'

type Props = {
  newsRelease: NewsRelease[]
}

export const News = ({ newsRelease }: Props) => {
  return (
    <NewsRoom>
      <SubSectionTitle>
        <DHeading>ニュースリリース</DHeading>
        <Link href="/news/release/" passHref>
          <a>
            <NewsMoreButton btnStyle="transparent">一覧を見る</NewsMoreButton>
          </a>
        </Link>
      </SubSectionTitle>
      <Spacer h={{ sp: 30, pc: 80 }} />
      <PRNewsList>
        {newsRelease.map(({ _id, tag, title, publishDate }, index) => (
          <NewsItem
            key={_id}
            publishDate={publishDate}
            tag={tag}
            title={title}
            link={`/news/release/${_id}`}
            isNotShowBottom={index === 2}
          />
        ))}
      </PRNewsList>
      <Spacer h={{ sp: 34, pc: 90 }} />
      <SubSectionTitle>
        <DHeading>IRニュース</DHeading>
        <Link href="/ir/news/" passHref>
          <a>
            <NewsMoreButton btnStyle="transparent">一覧を見る</NewsMoreButton>
          </a>
        </Link>
      </SubSectionTitle>
      <Spacer h={{ sp: 30, pc: 80 }} />
      <IRNewsList>
        <RecentNewsList />
      </IRNewsList>
    </NewsRoom>
  )
}

const NewsRoom = styled.section`
  width: 1040px;
  margin: 100px auto 0;

  @media screen and (max-width: 1047px) {
    width: 100%;
    margin: 40px auto 0;
  }
`

const SubSectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 1047px) {
    gap: 10px;
    padding: 0 20px;
  }
`

const PRNewsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  &[aria-hidden='true'] {
    display: none;
  }

  @media screen and (max-width: 1047px) {
    padding: 0 20px;
  }
`

const IRNewsList = styled.div`
  &[aria-hidden='true'] {
    display: none;
  }

  @media screen and (max-width: 1047px) {
    padding: 0 20px;
  }
`

const NewsMoreButton = styled(Button)`
  padding: 11px 26px 13px 26px;
  width: 135px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;

  @media screen and (max-width: 1047px) {
    padding: 2px 18px 4px 18px;
    font-size: 13px;
    width: 104px;
    height: 32px;
  }
`
