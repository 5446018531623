import Link from 'next/link'
import { Link as Scroll } from 'react-scroll'
import styled, { css } from 'styled-components'

import { theme } from '@/styles/theme'

import { Card } from './Card'

type LinkCardProps = {
  imgPath: string
  title?: string
  detail?: string
  link?: string
  isExternalLink?: boolean
  isScroll?: boolean
}

export const LinkCard = ({
  link,
  isExternalLink,
  isScroll,
  ...props
}: LinkCardProps) => {
  if (link) {
    if (isExternalLink) {
      return (
        <StyledLink href={link} target="_blank" rel="noopener noreferrer">
          <Card {...props} isExternalLink={isExternalLink} />
        </StyledLink>
      )
    }

    if (isScroll) {
      return (
        <StyledScroll to={link} smooth duration={600}>
          <Card {...props} />
        </StyledScroll>
      )
    }

    return (
      <Link href={link} passHref>
        <StyledLink>
          <Card {...props} />
        </StyledLink>
      </Link>
    )
  }

  return (
    <div>
      <Card {...props} />
    </div>
  )
}

const LinkStyle = css`
  display: block;
  width: 316px;
  white-space: pre-wrap;

  &:hover > div > div:first-of-type {
    > div > img {
      opacity: 0.6;
      transition: opacity 0.25s ease;
    }

    > div:nth-of-type(2) {
      background: ${theme.colors.hoverLightBlue};
      transition: background 0.25s ease;
    }
  }

  @media screen and (max-width: 1047px) {
    width: 335px;
  }
`

const StyledLink = styled.a`
  ${LinkStyle}
`

const StyledScroll = styled(Scroll)`
  ${LinkStyle}
`
