import Link from 'next/link'
import styled from 'styled-components'

import { theme } from '@/styles/theme'
import { formatDate } from '@/utils/date'

import { NewsTag } from '../NewsTag'
import type { NewsTagProps } from '../NewsTag'

export type NewsItemProps = {
  title: string
  tag: readonly NewsTagProps[]
  link: string
  publishDate: string
  isNotShowBottom?: boolean
}

export const NewsItem = ({
  publishDate,
  tag,
  title,
  link,
  isNotShowBottom,
}: NewsItemProps) => {
  return (
    <Article isNotShowBottom={isNotShowBottom}>
      <Link href={link} passHref>
        <Anchor>
          <Time dateTime={publishDate}>
            {formatDate(publishDate, 'yyyy/MM/dd')}
          </Time>
          <NewsDiv>
            {tag.length > 0 && (
              <TagList>
                {tag.map((value) => (
                  <NewsTag {...value} key={value.name} />
                ))}
              </TagList>
            )}
            <Title>{title}</Title>
          </NewsDiv>
        </Anchor>
      </Link>
    </Article>
  )
}

const Article = styled.article<{ isNotShowBottom?: boolean }>`
  padding-bottom: 30px;
  :not(:last-of-type) {
    border-bottom: ${({ isNotShowBottom }) =>
      isNotShowBottom ? `none` : `1px solid ${theme.colors.backgroundBlue}`};
  }

  @media screen and (max-width: 1047px) {
    padding-bottom: 26px;
  }
`

const Anchor = styled.a`
  display: flex;
  cursor: pointer;
  gap: 50px;

  &:hover > div > h3 {
    text-decoration: underline;
    color: ${theme.colors.trialBlue};
  }

  @media screen and (max-width: 1047px) {
    flex-direction: column;
    row-gap: 14px;
  }
`

const Time = styled.time`
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  color: ${theme.colors.textTitle};
`

const NewsDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
`

const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`

const Title = styled.h3`
  font-size: 16px;
  letter-spacing: 0.64px;
  line-height: 26px;
  color: ${theme.colors.textTitle};

  @media screen and (max-width: 1047px) {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
  }
`
