import styled from 'styled-components'

import { theme } from '@/styles/theme'

export type NewsTagProps = {
  name: string
  code?: string
}

export const NewsTag = (tag: NewsTagProps) => {
  const tagColor =
    tag.code === 'importantNotices' ? theme.colors.red : theme.colors.trialBlue

  return <Tag color={tagColor}>{tag.name}</Tag>
}

const Tag = styled.li<{ color?: string }>`
  font-size: 13px;
  text-align: center;
  line-height: 1;
  color: ${theme.colors.trialBlue};
  list-style: none;
  min-width: 82px;
  padding: 6px 13px;
  border: 1pt solid;
  border-radius: 2pt;
  border-color: ${({ color }) => color};
  color: ${({ color }) => color};
`
