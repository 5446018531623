import NextHead from 'next/head'

type OGP = {
  title?: string
  type?: 'website' | 'article'
  url: string
  image?: {
    src: string
    width: number
    height: number
  }
}

type Props = {
  title: string
  hasTitleSuffix?: boolean
  description: string
} & (
  | {
      noindex?: false
      ogp: OGP
    }
  | {
      noindex: true
      ogp?: never
    }
)

export const Head = ({
  title,
  hasTitleSuffix = true,
  description,
  noindex,
  ogp,
}: Props) => {
  const ogpImage = ogp?.image ?? {
    src: 'https://trial-holdings.inc/images/ogp.png',
    width: 1200,
    height: 630,
  }

  const titleText = hasTitleSuffix
    ? `${title} | トライアルホールディングス`
    : title

  return (
    <NextHead>
      <title>{titleText}</title>
      <meta name="description" content={description} />
      {noindex && <meta name="robots" content="noindex, nofollow" />}
      {ogp && (
        <>
          <meta property="og:type" content={ogp.type ?? 'website'} />
          <meta
            property="og:url"
            content={`https://trial-holdings.inc/${ogp.url}`}
          />
          <meta property="og:title" content={ogp.title || titleText} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={ogpImage.src} />
          <meta property="og:image:width" content={`${ogpImage.width}`} />
          <meta property="og:image:height" content={`${ogpImage.height}`} />
          <meta property="og:site_name" content="トライアルホールディングス" />
          <meta property="og:locale" content="ja_JP" />
        </>
      )}
      <link rel="icon" href="/images/favicon.ico" />
    </NextHead>
  )
}
