import styled, { css } from 'styled-components'
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'

import { theme } from '@/styles/theme'
import { PxToVw } from '@/utils/convertPx'

import { IrPickUp } from './IrPickUp'
import { News } from './News'
import { PickUpItem, PickUpItemProps } from './PickUpItem'
import { SectionTitle } from '@/components/elements/SectionTitle'
import { SectionTitleBackground } from '@/components/elements/SectionTitleBackground'
import { Spacer } from '@/components/elements/Spacer'
import { Layout } from '@/components/layout'
import { Head } from '@/components/layouts/Head'

import type { NewsRelease } from '@/types/newsRelease'

SwiperCore.use([Pagination, Navigation, Autoplay])

type HomePageProps = {
  newsRelease: NewsRelease[]
}

export const HomePage = ({ newsRelease }: HomePageProps) => {
  const images = [
    '/images/home-slide-1.webp',
    '/images/home-slide-2.webp',
    '/images/home-slide-3.webp',
    '/images/home-slide-4.webp',
    '/images/home-slide-5.webp',
  ]

  const pickupList: PickUpItemProps[] = [
    {
      link: '/company/topmessage/',
      imgPath: '/images/home-top-message.svg',
      imgWidth: 133,
      imgHeight: 174,
      title: 'トップメッセージ',
      alignItems: 'flex-end',
      cssProp: css`
        img {
          max-width: 100%;
          transform: translateX(23%);
        }
      `,
    },
    {
      link: '/company/philosophy/',
      imgPath: '/images/home-philosophy.svg',
      imgWidth: 100,
      imgHeight: 195,
      title: '経営理念',
      alignItems: 'center',
      cssProp: css`
        img {
          max-height: 120px;
        }
      `,
    },
    {
      link: '/news/blog/',
      imgPath: '/images/home-pr-blog.svg',
      imgWidth: 186,
      imgHeight: 147,
      title: '広報ブログ',
      alignItems: 'center',
      cssProp: css`
        img {
          max-width: 112px;
          transform: translateY(6%);
        }
      `,
    },
  ]

  return (
    <Layout>
      <>
        <Head
          title="トライアルホールディングス | リテールDXを通じて世の中を豊かな社会へ"
          hasTitleSuffix={false}
          description="トライアルホールディングスは「世界の誰もが豊かさを享受できる社会をつくる。」をビジョンに掲げ、テクノロジーと、人の経験知で、世界のリアルコマースの変革を促進する企業です。"
          ogp={{ url: '' }}
        />
        <Wrapper>
          <TopSlider>
            <SlideWrapper>
              <p>
                世界の誰もが「豊かさ」を
                <br />
                享受できる社会をつくる。
              </p>
              <Swiper
                style={{ zIndex: 1 }}
                slidesPerView={1}
                pagination={{
                  clickable: true,
                  bulletClass: `swiper-pagination-bullet custom-bullet`,
                  bulletActiveClass: `swiper-pagination-bullet-active custom-bullet-active`,
                }}
                loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                speed={2000}
              >
                {images.map((src: string, index: number) => {
                  return (
                    <SwiperSlide key={`${index}`}>
                      <SlideImage src={src} alt="" />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </SlideWrapper>
          </TopSlider>
          <News newsRelease={newsRelease} />
          <Spacer h={{ pc: 30, sp: 50 }} />
          <PickUp>
            <SectionTitleBackground
              padding={{
                pc: `${PxToVw(210, 1440)} 0 ${PxToVw(130, 1440)}`,
                sp: `87px 0 80px`,
              }}
              bgColor="trial-blue"
              outerBgColor="white"
            >
              <SectionTitle
                color="white"
                titleText="Pick Up"
                subTitleText="ピックアップ"
                as="h2"
              />
              <PickUpList>
                {pickupList.map(
                  ({
                    link,
                    imgPath,
                    title,
                    imgWidth,
                    imgHeight,
                    alignItems,
                    cssProp,
                  }) => (
                    <PickUpItem
                      key={title}
                      link={link}
                      imgPath={imgPath}
                      title={title}
                      imgWidth={imgWidth}
                      imgHeight={imgHeight}
                      alignItems={alignItems}
                      cssProp={cssProp}
                    />
                  )
                )}
              </PickUpList>
            </SectionTitleBackground>
          </PickUp>
          <IrPickUp />
        </Wrapper>
      </>
    </Layout>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
`

const SlideImage = styled.img`
  width: 100vw;
  height: ${PxToVw(643, 1440)};
`

const TopSlider = styled.section`
  .swiper-pagination {
    bottom: 27px;
  }

  .swiper-pagination-bullet.custom-bullet {
    background-color: #fff;
    margin: 0 18px !important;
    width: 14px;
    height: 14px;
    opacity: 1;
  }

  .swiper-pagination-bullet-active.custom-bullet-active {
    background-color: ${theme.colors.trialBlue};
  }

  @media screen and (max-width: 1047px) {
    .swiper-pagination {
      position: relative !important;
      bottom: 5px;
      margin-top: 13px;
    }

    .swiper-pagination-bullet.custom-bullet {
      width: 11px;
      height: 11px;
      background-color: ${theme.colors.backgroundBlue};
    }

    .swiper-pagination-bullet-active.custom-bullet-active {
      background-color: ${theme.colors.trialBlue};
    }
  }
`

const PickUp = styled.section`
  // TODO IRを追加するときは削除する
  > div::after {
    display: none;
  }
`

const PickUpList = styled.ul`
  margin: 70px auto 0;
  display: flex;
  justify-content: space-around;
  column-gap: 30px;
  width: 1040px;

  @media screen and (max-width: 1047px) {
    width: 100%;
    margin: 34px auto 0;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    padding: 0 20px;
  }
`

const SlideWrapper = styled.div`
  position: relative;

  p {
    // position: absolute;
    z-index: 2;
    top: ${PxToVw(110, 1440)};
    color: #fff;
    font-size: ${PxToVw(30, 1440)};
    font-weight: bold;
    letter-spacing: 3.2px;
    line-height: 46px;
    background-color: rgba(0, 45, 101, 0.9);
    padding-top: ${PxToVw(20, 1440)};
    padding-left: ${PxToVw(100, 1440)};
    padding-bottom: ${PxToVw(20, 1440)};
    padding-right: 20px;
    // text-align: center;


    br {
      display: none;
    }
  }

  @media screen and (max-width: 1000px) {
    p {
      font-size: ${PxToVw(14, 390)};
      letter-spacing: 1.4px;
      line-height: 26px;
      line-height: ${PxToVw(20, 390)};

      padding-top: ${PxToVw(5, 390)};
      padding-bottom: ${PxToVw(5, 390)};
      padding-right: ${PxToVw(24, 390)};
      padding-left: ${PxToVw(34, 390)};

      br {
        display: block;
      }
    }
  }
`
